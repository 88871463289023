import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../common/DOMutils"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { dom } from "../../../../common/tsxrender/dom"
import { FilterLabel } from "./filterLabel"
import type { FilterOptionProps } from "./constants"


export class FilterOption extends HTMLComponent<HTMLAnchorElement, FilterOptionProps> {
    private filterLabel: FilterLabel | undefined
    protected props: FilterOptionProps

    protected createElement(props: FilterOptionProps): HTMLAnchorElement {
        return <a
            bind={{
                className: () => `filterOption ${this.props.optionIsActive() ? "active" : ""}`,
                href: () => props.getHref(),
            }}
            onClick={(e: MouseEvent) => { ignoreMetaClick(e, () => this.props.handleLeftClick())  }}
            data-testid={props.testid}
        >
            {props.name}
        </a>
    }

    protected initData(props: FilterOptionProps): void {
        super.initData(props)
        this.props = props
    }

    protected initUI(props: FilterOptionProps): void {
        super.initUI(props)
        this.updateState()  // trigger the "bind" updates in createElement
    }

    private createOrRemoveLabel() {
        const labelText = this.props.labelText ?? ""
        if (this.props.optionIsActive() && !labelText.startsWith("#")) {
            this.createFilterLabelButton()
        } else {
            this.removeLabelButton()
        }
    }

    private createFilterLabelButton(): void {
        if (this.filterLabel === undefined) {
            this.filterLabel = new FilterLabel({
                title: this.props.labelText ?? this.props.name,
                onRemoval: () => { this.props.handleLeftClick() },
            })
        }
    }

    public removeLabelButton(): void {
        // this method is exposed so that labels can be removed by other components, even if the current
        // urlState indicates that the option is active
        if (this.filterLabel !== undefined) {
            this.filterLabel.element.remove()
            delete this.filterLabel
        }
    }

    public updateState(): void {
        // this method will update the "bind"ings in this element and, depending on the current urlState, will either
        // create or remove the filter label for the option.  This method is public because filterOption does not
        // listen to UrlState changes, and other elements may need to control filterOption state
        super.updateState()
        if (!isFilterInPathActive()) {
            this.createOrRemoveLabel()
        }
    }

    public getValue(): string {
        return this.props.queryParamValue
    }
}
