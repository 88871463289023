import { t } from "@lingui/macro"
import { REGION_FILTER_KEY, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../../../common/DOMutils"
import { isLinguiBasicActive } from "../../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { REGION_MAPPING, REGION_OPTIONS } from "../constants"
import { FilterSectionBase } from "./filterSectionBase"
import type { FilterSectionProps } from "./filterSectionBase"
import type { Region } from "@multimediallc/cb-roomlist-prefetch"

export class RegionSection extends HTMLComponent<HTMLDivElement> {
    protected createElement(): HTMLDivElement {
        const mappings = REGION_MAPPING
        return <div className="filterSection" data-testid="filter-regions-section">
            <div className="filterSectionHeader" data-testid="filter-regions-header">
                {isLinguiBasicActive() ? t`REGIONS` : i18n.regionsCAPS}
            </div>
            <div className="filterSectionOptions">
                {[...mappings.keys()].map(k => (
                    <a
                        bind={{
                            className: () => `filterOption ${UrlState.current.state.regions?.includes(k) ?? false ? "active" : ""}`,
                            href: () => UrlState.current.getURLForPartialState({ regions: getToggledRegions(k) }),
                        }}
                        onClick={(e: MouseEvent) => {
                            ignoreMetaClick(e, () => {
                                const newRegions = getToggledRegions(k)
                                UrlState.current.setPartialState({ regions: newRegions })
                                addPageAction("HmpgFilterOptionClicked", {
                                    "category": "regions",
                                    "value": k,
                                    "active": newRegions.includes(k),
                                })
                            })
                        }}
                        data-testid="filter-regions-item"
                    >{mappings.get(k)}</a>
                ))}
            </div>
        </div>
    }
}

function getToggledRegions(newRegion: Region): Region[] {
    const state = UrlState.current.state
    const regions = state.regions ?? []
    const active = regions.includes(newRegion)
    const newRegions = active ? regions.filter((r: string) => r !== newRegion) : [...regions, newRegion]
    return newRegions.sort()
}

export class RegionFilterSection extends FilterSectionBase {

    protected createElement(props: FilterSectionProps): HTMLDivElement {
        this.filterKey = REGION_FILTER_KEY
        this.sectionOptions = REGION_OPTIONS
        this.sectionTitle = isLinguiBasicActive() ? t`REGIONS` : i18n.regionsCAPS
        this.isSingleSelect = false
        return super.createElement(props)
    }

    protected optionIsActive(queryParamValue: string): boolean {
        const currentValue = UrlState.current.state["regions"]
        if (currentValue === undefined) {
            return false
        }
        for (const val of currentValue) {
            if (val.toString() === queryParamValue) {
                return true
            }
        }
        return false
    }
}
