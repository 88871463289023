import {
    FILTER_PANEL_KEYS,
    getFilterPanelOpenCached,
    HOMEPAGE_KEYS_NO_PAGE,
    isLanguageFilterActive,
    isRoomSizeFilteringActive,
    shouldShowHomepageFilters,
    SPOKEN_LANGUAGE_FILTER_KEY,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isFilterInPathActive, isSpyPriceFiltersActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactWrapper } from "../../ReactWrapper"
import { HomepageFilterButton } from "./filterButton"
import { AgeSection } from "./filterSections/ageSection"
import { getToggledUrl } from "./filterSections/filterSectionBase"
import { PrivatePriceFilterSection, PrivatePriceSection } from "./filterSections/privatePriceSection"
import { RegionFilterSection, RegionSection } from "./filterSections/regionSection"
import { RoomSizeFilterSection, RoomSizeSection } from "./filterSections/roomSizeSection"
import { SpyShowPriceSection } from "./filterSections/spyShowPriceSection"
import { TagSection } from "./filterSections/tagSection"
import { cacheHomepageFilters } from "./homepageFiltersUtil"


interface FilterPanelProps {
    handleClose?: () => void
    onFilterOptionClick?: () => void
}

interface FilterPanelState {
    isOpen: boolean
}

export class HomepageFilterPanel extends HTMLComponent<HTMLDivElement, FilterPanelProps, FilterPanelState> {
    private props: FilterPanelProps
    private regionSection?: RegionFilterSection
    private priceSection?: PrivatePriceFilterSection
    private roomSizeSection?: RoomSizeFilterSection
    private ageSection?: AgeSection

    // eslint-disable-next-line complexity
    protected createElement(props: FilterPanelProps): HTMLDivElement {
        return <div className="homepageFilterPanel" bind={{
            display: () => shouldShowHomepageFilters() && this.state.isOpen ? "inline-block" : "none",
        }}>
            <div onClick={() => this.handleCloseButtonClick()} className="closeButton">
                <ReactWrapper
                    component="CloseButton"
                    componentProps={{}}
                />
            </div>
            {isSpyPriceFiltersActive() && !new URLSearchParams(window.location.search).has("spyLast") && <SpyShowPriceSection/>}
            {isFilterInPathActive() ? <RegionSection/> : <RegionFilterSection
                classRef={(c) => { this.regionSection = c }}
                onFilterOptionClick={props.onFilterOptionClick!}
            />}
            {isRoomSizeFilteringActive() && (isFilterInPathActive() ? <RoomSizeSection/> : <RoomSizeFilterSection
                classRef={(c) => { this.roomSizeSection = c }}
                onFilterOptionClick={props.onFilterOptionClick!}
            />)}
            {(isLanguageFilterActive()) && <ReactWrapper
                component="LanguageFilterSection"
                componentProps={{
                    onFilterOptionClick: ((selectedLanguage: string) => onLanguageFilterOptionClick(props, selectedLanguage)),
                    urlState: UrlState.current,
                    getToggledHref: getToggledLanguageHref,
                }}
            />}
            <TagSection
                onFilterOptionClick={props.onFilterOptionClick ?? (() => {})}
            />
            {isFilterInPathActive() ? <AgeSection/> : <AgeSection classRef={(c: AgeSection) => { this.ageSection = c }}
                onUpdate={props.onFilterOptionClick}
            />}
            {isFilterInPathActive() ? <PrivatePriceSection/> : <PrivatePriceFilterSection
                classRef={(c) => { this.priceSection = c }}
                onFilterOptionClick={props.onFilterOptionClick!}
            />}
            {isSpyPriceFiltersActive() && (new URLSearchParams(window.location.search).has("spyLast")) && <SpyShowPriceSection/>}
        </div>
    }

    protected initData(props: FilterPanelProps): void {
        super.initData(props)
        this.setState({ isOpen: getFilterPanelOpenCached() })
        this.props = props
        if (isFilterInPathActive()) {
            UrlState.current.listen(HOMEPAGE_KEYS_NO_PAGE, () => {
                this.updateState()
            }, this.element)
            HomepageFilterButton.filterPanelOpen.listen((isActive) => {
                this.setState({ ...this.state, isOpen: isActive })
            })
        }
    }

    private handleCloseButtonClick(): void {
        if (isFilterInPathActive()) {
            HomepageFilterButton.filterPanelOpen.fire(false)
        } else if (this.props.handleClose !== undefined) {
            this.props.handleClose()
        }
        addPageAction("HmpgFilterPanelMovement", {
            "open": this.state.isOpen,
        })
    }

    public clearAll(): void {
        UrlState.current.clearStateKeys([...FILTER_PANEL_KEYS], false)

        this.ageSection?.resetAgeFilters()
        this.regionSection?.updateSectionFromUrl()
        this.priceSection?.updateSectionFromUrl()
        this.roomSizeSection?.updateSectionFromUrl()

        // cache current (null) filters selection
        cacheHomepageFilters()
    }

    public updateOptionsAndLabelsFromUrl(): void {
        this.ageSection?.updateStateFromUrl()
        this.regionSection?.updateSectionFromUrl()
        this.priceSection?.updateSectionFromUrl()
        this.roomSizeSection?.updateSectionFromUrl()
    }
}

export function onLanguageFilterOptionClick(props: FilterPanelProps, selectedLanguage: string): void {
    addPageAction("HmpgFilterOptionClicked", {
        "category": SPOKEN_LANGUAGE_FILTER_KEY,
        "value": selectedLanguage,
        "active":  UrlState.current.state.spokenLanguages?.[0] !== selectedLanguage,
    })

    if (!isFilterInPathActive()) {
        UrlState.current.pushUrl(getToggledLanguageHref(selectedLanguage))
        props.onFilterOptionClick?.()
    } else {
        if (UrlState.current.state.spokenLanguages?.[0] === selectedLanguage) {
            UrlState.current.clearStateKeys(["spokenLanguages"])
        } else {
            UrlState.current.setPartialState({ spokenLanguages: [selectedLanguage]})
        }
    }
}

function getToggledLanguageHref(language: string): string {
    return getToggledUrl(SPOKEN_LANGUAGE_FILTER_KEY, language, true).href
}
